import axios from 'axios';

// Set default base URL
// axios.defaults.baseURL = 'http://localhost:5001/api';
// production
axios.defaults.baseURL = 'https://platform.schetito.bg/api';
axios.defaults.withCredentials = true;

// Add authorization token to request headers
axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

let isRefreshing = false;
let refreshSubscribers = [];

// Handle API errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const originalConfig = error.config;
        if (error.response) {
            // Handle specific error codes or messages
            if (error.response.status === 401 && !originalConfig._retry) {
                if (!isRefreshing) {
                    isRefreshing = true;
          
                    return axios.post('/user/refresh-token', {}, { withCredentials: true })
                      .then((res) => {
                        if(res.data.error) {
                          console.log('Refresh token not refreshed');
                          return Promise.reject(error);
                        } else {
                          console.log('Refresh token refreshed');
                          // 1) put token to LocalStorage
                          localStorage.setItem('token', res.data.token);
                          // 2) Change Authorization header
                          axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
                          // 3) return originalRequest object with Axios.
                          originalConfig.headers.Authorization = `Bearer ${res.data.token}`;
                          refreshSubscribers.forEach((callback) => callback(null, res.data.token));
                          refreshSubscribers = [];
                          return axios(originalConfig);
                        }
                      })
                      .catch((err) => {
                        console.log('Refresh token not refreshed in catch');
                        refreshSubscribers.forEach((callback) => callback(err, null));
                        refreshSubscribers = [];

                        //logut 
                        localStorage.removeItem('token');
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        window.location.href = '/login';
                        return Promise.reject(err);
                      })
                      .finally(() => {
                        isRefreshing = false;
                      });
                  } else {
                    return new Promise((resolve, reject) => {
                      refreshSubscribers.push((err, token) => {
                        if (err) {
                          reject(err);
                        } else {
                          originalConfig.headers.Authorization = `Bearer ${token}`;
                          resolve(axios(originalConfig));
                        }
                      });
                    });
                  }
            } else if (error.response.status === 404) {
                // Not found error
                // Show error message or handle accordingly
                return Promise.reject({ message: '404 page not found' });
            } else {
                // Other error codes
                // Show generic error message or handle accordingly
                return Promise.reject({ message: error.response.data.message || 'Something went wrong' });
            }
        } else {
            // Network error or request timeout
            // Show error message or handle accordingly
        }
        return Promise.reject(error);
    }
);

export default axios;
