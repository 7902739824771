import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../app/api'

const initialState = {
    clients: null,
    loading: false,
    error: null,
};

export const addClient = createAsyncThunk(
    'clients/addClient',
    async ({
        name, eik, ddsNumber, country, city, address, mol, email, company_id
    }, { rejectWithValue }) => {
        try {
            const response = await api.post('/clients/addClient', {
                name, eik, ddsNumber, country, city, address, mol, email, company_id
             });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

export const getUserClients = createAsyncThunk(
    'clients/getUserClients',
    async (dispatch, { rejectWithValue }) => {
        try {
            const response = await api.post('/clients/getUserClients', {
                company_id: dispatch.company_id 
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(addClient.pending, (state) => {
                state.loading = true;
            })
            .addCase(addClient.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addClient.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getUserClients.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserClients.fulfilled, (state, action) => {
                state.clients = action.payload.clients;
                state.loading = false;
            })
            .addCase(getUserClients.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
    }
});

export default clientsSlice.reducer;