import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../app/api'

const initialState = {
    invoices: [],
    invoice: null,
    loading: false,
    error: null,
};

export const addInvoice = createAsyncThunk(
    'invoice/addInvoice',
    async ({
        date, number, type_of_payment, bank_account, total, productTables, company_id, client_id
    }, { rejectWithValue }) => {
        try {
            const response = await api.post('/invoice/addInvoice', {
                date, number, type_of_payment, bank_account, total, productTables, company_id, client_id
             });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

export const generateNextInvoiceNumber = createAsyncThunk(
    'invoice/generateNextInvoiceNumber',
    async (dispatch, { rejectWithValue }) => {
        try {
            const response = await api.post('/invoice/generateNextInvoiceNumber', {
                company_id: dispatch.company_id
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const getCompanyInvoices = createAsyncThunk(
    'invoice/getCompanyInvoices',
    async (dispatch, { rejectWithValue }) => {
        try {
            const response = await api.post('/invoice/getCompanyInvoices', {
                company_id: dispatch.company_id 
            });
            return response.data;   
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

export const deleteIncome = createAsyncThunk(
    'invoice/deleteIncome',
    async ({id, type}, { rejectWithValue }) => {
        try {
            const response = await api.post(`/invoice/deleteIncome/${id}`, { type });
            return response.data;             
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const getInvoice = createAsyncThunk(
    'invoice/getInvoice',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.post(`/invoice/getInvoice/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const editInvoice = createAsyncThunk(
    'invoice/editInvoice',
    async ({
        id, date, type_of_payment, bank_account, total, productTables, company_id, client_id
    }, { rejectWithValue }) => {
        try {
            const response = await api.post(`/invoice/editInvoice/${id}`, {
                date, type_of_payment, bank_account, total, productTables, company_id, client_id
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const generateInvoice = createAsyncThunk(
    'invoice/generateInvoice',
    async ({ id },{ rejectWithValue }) => {
        try {
            const response = await api.post('/invoice/generateInvoice', {
                id
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');   
        }   
    }
);


const clientsSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(addInvoice.pending, (state) => {
                state.loading = true;
            })
            .addCase(addInvoice.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addInvoice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(generateNextInvoiceNumber.pending, (state) => {
                state.loading = true;
            })
            .addCase(generateNextInvoiceNumber.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(generateNextInvoiceNumber.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getCompanyInvoices.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompanyInvoices.fulfilled, (state, action) => {
                state.loading = false;
                state.invoices = action.payload.invoices;
            })
            .addCase(getCompanyInvoices.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteIncome.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteIncome.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteIncome.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getInvoice.pending, (state) => {
                state.loading = true;
            })
            .addCase(getInvoice.fulfilled, (state, action) => {
                state.loading = false;
                state.invoice = action.payload;
            })
            .addCase(getInvoice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(editInvoice.pending, (state) => {
                state.loading = true;
            })
            .addCase(editInvoice.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(editInvoice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default clientsSlice.reducer;