import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import usersReducer from './features/users'
import expensesReducer from './features/expenses'
import clientsReducer from './features/clients'
import invoicesReducer from './features/invoices'
import monthReport from './features/month-report';
import insuranceReducer from './features/insurance';
import importExportMoneyRerducer from './features/importExportMoney';

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  users: usersReducer,
  expenses: expensesReducer,
  clients: clientsReducer,
  invoices: invoicesReducer,
  monthReport: monthReport,
  insurances: insuranceReducer,
  importExportMoney: importExportMoneyRerducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'users/logoutUser') {
    //clean up state on logout
    state = undefined;
    localStorage.removeItem('token');
  }
  return reducers(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };