import React, { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { logoutUser } from '../features/users'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';


const PrivateRoute = () => {
    
    const isAuthenticated = useSelector((state) => state.users.isAuthenticated);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    if(!isAuthenticated) {
        localStorage.removeItem('token');
        dispatch(logoutUser());
        navigate('/login');
    }

    return isAuthenticated ? (
      <DashboardLayout>
              <Suspense>
                <Outlet />
              </Suspense>
            </DashboardLayout>
    ) : <Navigate to="/login" />;
}

export default PrivateRoute;