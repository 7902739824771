import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../app/api'


// Define the initial state
const initialState = {
    user: null,
    isAuthenticated: false,
    company: null,
    companyIncome: null,
    companyExpenses: null,
    cashAvailability: null,
    bankAvailability: null,
    loading: false,
    error: null,
};

// Define the async thunk for user login
export const loginUser = createAsyncThunk(
    'users/login',
    async ({ email, password }, { rejectWithValue }) => {
        try {
            const response = await api.post('/user/login', { email, password });
            // set the token in localStorage
            localStorage.setItem('token', response.data.token);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

//refresh token
export const refreshToken = createAsyncThunk(
    'users/refreshToken',
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.post('/user/refresh-token', {}, { withCredentials: true });
            // set the token in localStorage
            console.log(response.data);
            localStorage.setItem('token', response.data.token);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

//update profile
export const updateProfile = createAsyncThunk(
    'users/updateProfile',
    async ({ email, fname, mname, lname, egn }, { rejectWithValue }) => {
        try {
            const response = await api.post('/user/update-profile', { email, fname, mname, lname, egn });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

//change password
export const changePassword = createAsyncThunk(
    'users/changePassword',
    async ({ email, oldPassword, newPassword }, { rejectWithValue }) => {
        try {
            const response = await api.post('/user/change-password', { email, oldPassword, newPassword });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const registerUser = createAsyncThunk(
    'users/register',
    async ({ email, password, fname, mname, lname, egn }, { rejectWithValue }) => {
        try {
            const response = await api.post('/user/register', { email, password, fname, mname, lname, egn });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

export const resetPasswordMail = createAsyncThunk(
    'users/resetPasswordMail',
    async ({ email }, { rejectWithValue }) => {
        try {
            const response = await api.post('/user/reset-password-mail', { email });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const resetPassword = createAsyncThunk(
    'users/resetPassword',
    async ({ password, resetCode }, { rejectWithValue }) => {
        try {
            const response = await api.post('/user/reset-password', { password, resetCode });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const addCompany = createAsyncThunk(
    'users/addCompany',
    async ({ name, type, eik, ddsNumber, mol, country, city, address, company_activity }, { rejectWithValue }) => {
        try {
            const response = await api.post('/company/company', { name, type, eik, ddsNumber, mol, country, city, address, company_activity });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

export const updateCompany = createAsyncThunk(
    'users/updateCompany',
    async ({ id, name, type, eik, ddsNumber, mol, country, city, address, company_activity }, { rejectWithValue }) => {
        try {
            const response = await api.put(`/company/company/${id}`, { name, type, eik, ddsNumber, mol, country, city, address, company_activity });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const companyIncomes = createAsyncThunk(
    'users/companyIncome',
    async ({ company_id, year }, { rejectWithValue }) => {
        try {
            const response = await api.post('/user/calculate-company-incomes', { company_id, year });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const companyExpenses = createAsyncThunk(
    'users/companyExpenses',
    async ({ company_id, year }, { rejectWithValue }) => {
        try {
            const response = await api.post('/user/calculate-company-expenses', { company_id, year });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const calculateCashAvailability = createAsyncThunk(
    'users/cashAvailability',
    async ({ company_id }, { rejectWithValue }) => {
        try {
            const response = await api.post('/user/calculate-cash-availability', { company_id });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const calculateBankAvailability = createAsyncThunk(
    'users/bankAvailability',
    async ({ company_id }, { rejectWithValue }) => {
        try {
            const response = await api.post('/user/calculate-bank-availability', { company_id });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

// Define the slice
const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        logoutUser(state) {
            state.user = null;
            state.isAuthenticated = false;
            localStorage.removeItem('token');
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload.user;
                state.company = action.payload.company;
                state.isAuthenticated = true;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.isAuthenticated = false;
            })
            .addCase(registerUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addCompany.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addCompany.fulfilled, (state, action) => {
                state.loading = false;
                state.company = action.payload.newCompany;
                state.user.company_id = action.payload.newCompany.id;
            })
            .addCase(addCompany.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateCompany.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateCompany.fulfilled, (state, action) => {
                state.loading = false;
                state.company = action.payload.company;
            })
            .addCase(updateCompany.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload.user;
            })
            .addCase(updateProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(changePassword.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(changePassword.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(companyIncomes.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(companyIncomes.fulfilled, (state, action) => {
                state.loading = false;
                state.companyIncome = action.payload;
            })
            .addCase(companyIncomes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(companyExpenses.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(companyExpenses.fulfilled, (state, action) => {
                state.loading = false;
                state.companyExpenses = action.payload;
            })
            .addCase(companyExpenses.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(calculateCashAvailability.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(calculateCashAvailability.fulfilled, (state, action) => {
                state.loading = false;
                state.cashAvailability = action.payload;
            })
            .addCase(calculateCashAvailability.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(calculateBankAvailability.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(calculateBankAvailability.fulfilled, (state, action) => {
                state.loading = false;
                state.bankAvailability = action.payload;
            })
            .addCase(calculateBankAvailability.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(resetPasswordMail.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(resetPasswordMail.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(resetPasswordMail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(resetPassword.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(resetPassword.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});

// Export actions
export const { logoutUser } = usersSlice.actions;
export default usersSlice.reducer;
