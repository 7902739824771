import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../app/api'

const initialState = {
    month_report: [],
    loading: false,
    error: null,
};

export const addMonthReport = createAsyncThunk(
    'month_report/addMonthReport',
    async ({
        date, total, company_id, month, year
    }, { rejectWithValue }) => {
        try {
            const response = await api.post('/month-report/add-month-report', {
                date, total, company_id, month, year
             });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

export const getMonthReports = createAsyncThunk(
    'month_report/getMonthReports',
    async (dispatch, { rejectWithValue }) => {
        try {
            const response = await api.post('/month-report/get-company-reports', {
                company_id: dispatch.company_id 
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

const clientsSlice = createSlice({
    name: 'month_report',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(addMonthReport.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addMonthReport.fulfilled, (state, action) => {
                state.loading = false;
                // state.month_report = action.payload;
            })
            .addCase(addMonthReport.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getMonthReports.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getMonthReports.fulfilled, (state, action) => {
                state.loading = false;
                state.month_report = action.payload.monthReports;
            })
            .addCase(getMonthReports.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    }
});

export default clientsSlice.reducer;