import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../app/api'

const initialState = {
    importExportMoney: [],
    loading: false,
    error: null,
};

export const addImportExportMoney = createAsyncThunk(
    'importExportMoney/addImportExportMoney',
    async ({ total, typeOfOperation, toWhere, type, company_id }) => {
        const response = await api.post('/import-export-money/importExportMoney', { total, typeOfOperation, toWhere, type, company_id });
        return response.data;
    }
);

export const getAllImportExportMoney = createAsyncThunk(
    'importExportMoney/getAllImportExportMoney',
    async ({ company_id }) => {
        const response = await api.post('/import-export-money/importExportMoney/all', { company_id });
        console.log(response.data);
        return response.data;
    }
);

const importExportMoneySlice = createSlice({
    name: 'importExportMoney',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addImportExportMoney.pending, (state) => {
                state.loading = true;
            })
            .addCase(addImportExportMoney.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addImportExportMoney.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getAllImportExportMoney.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllImportExportMoney.fulfilled, (state, action) => {
                state.importExportMoney = action.payload.importExportMoney;
                state.loading = false;
            })
            .addCase(getAllImportExportMoney.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
    }
});

export default importExportMoneySlice.reducer;