import SvgColor from '../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`../../assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'Табло',
    path: '/',
    icon: icon('tablo'),
  },
  {
    title: 'Разходи',
    path: '/expenses',
    icon: icon('razhod'),
  },
  {
    title: "Приходи",
    path: '/incomes',
    icon: icon('prihod'),
  },
  {
    title: "Справки",
    path: '/warehouse',
    icon: icon('spravki'),
  },
  {
    title: "Осигурителен доход",
    path: '/social-insurance',
    icon: icon('osiguritelen-dohod'),
  },
  {
    title: "Импорт/Експорт на средства",
    path: '/import-export-money',
    icon: icon('eksport-na-sredstva'),
  }
];

export default navConfig;
