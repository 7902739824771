import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useDispatch, useSelector} from 'react-redux';
import { addCompany } from '../../features/users';

const AddCompanyForm = () => {
    const dispatch = useDispatch();

    const [name, setCompanyName] = useState('');
    const [type, setCompanyType] = useState('');
    const [eik, setCompanyEIK] = useState('');
    const [isDDS, setIsDDS] = useState(false);
    const [mol, setCompanyMOL] = useState('');
    const [country, setCompanyCountry] = useState('bg');
    const [city, setCompanyCity] = useState('');
    const [address, setCompanyAddress] = useState('');
    const [company_activity, setCompanyActivity] = useState('0');

    const [error, setError] = useState(null);
    const serverError = useSelector((state) => state.users.error);

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!validator()) {
            return;
        }
        dispatch(addCompany({ name, type, eik, mol, country, city, address, company_activity }))
    };

    const validator = () => {
        setError('');
        if (name.length < 3) {
            setError('Името на фирмата трябва да е поне 3 символа');
            return false;
        }
        if (type.length < 1) {
            setError('Mоля въведете тип на фирмата');
            return false;
        }
        if (eik.length < 9) {
            setError('Моля въведете валиден ЕИК');
            return false;
        }
        if (mol.length < 3) {
            setError('Моля въведете валиден МОЛ');
            return false;
        }
        if (city.length < 3) {
            setError('Моля въведете валиден град');
            return false;
        }
        if (address.length < 3) {
            setError('Моля въведете валиден адрес');
            return false;
        }
        if (company_activity === '0') {
            setError('Моля изберете дейност');
            return false;
        }

        return true;
    };
    

    const renderForm = (
        <>
            <Stack spacing={3}>
                <TextField
                    name="name"
                    label="Име на фирмата"
                    type="text"
                    value={name}
                    onChange={(e) => setCompanyName(e.target.value)}
                />

                <TextField
                    name="type"
                    label="Тип на фирмата"
                    type="text"
                    placeholder="ЕООД, ЕТ, ООД"
                    value={type}
                    onChange={(e) => setCompanyType(e.target.value)}
                />

                <TextField
                    name="eik"
                    label="ЕИК"
                    type="text"
                    value={eik}
                    onChange={(e) => setCompanyEIK(e.target.value)}
                />

                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isDDS} onChange={(e) => setIsDDS(e.target.checked)} />} label="Регистрация по ДДС" />
                </FormGroup>
                

                {isDDS && (
                    <Typography variant="body1" sx={{ color: 'red', mt: 2 }}>
                        В момента не поддържаме регистрация по ДДС, очаквайте скоро!
                    </Typography>
                )}

                <TextField
                    name="mol"
                    label="МОЛ"
                    type="text"
                    value={mol}
                    onChange={(e) => setCompanyMOL(e.target.value)}
                />

                <Select
                    id="country"
                    value={country}
                    label="Държава"
                    onChange={(e) => setCompanyCountry(e.target.value)}
                >
                    <MenuItem value="bg">България</MenuItem>
                </Select>

                <TextField
                    name="city"
                    label="Град"
                    type="text"
                    value={city}
                    onChange={(e) => setCompanyCity(e.target.value)}
                />

                <TextField
                    name="address"
                    label="Адрес"
                    type="text"
                    value={address}
                    onChange={(e) => setCompanyAddress(e.target.value)}
                />

                <Select
                    id="deinost"
                    value={company_activity}
                    label="Дейност"
                    onChange={(e) => setCompanyActivity(e.target.value)}
                >
                    <MenuItem value="0">Изберете дейност</MenuItem>
                    <MenuItem value="1">Фризьорски услуги</MenuItem>
                    <MenuItem value="2">Козметични услуги</MenuItem>
                    <MenuItem value="3">Маникюр и педикюр</MenuItem>
                    <MenuItem value="4">Масажи</MenuItem>
                    <MenuItem value="5">Автомонтьор</MenuItem>
                    <MenuItem value="6">Свободна професия/фрийлансър</MenuItem>
                    <MenuItem value="7">Заначтчия</MenuItem>
                    <MenuItem value="8">Земеделски производител</MenuItem>
                    <MenuItem value="9">Онлайн магазин</MenuItem>
                    <MenuItem value="10">Друго</MenuItem>
                </Select>
            </Stack>
            {error && (
                <Typography variant="body1" sx={{ color: 'red', mt: 2 }}>
                    {error}
                </Typography>
            )}
            {serverError && (
                <Typography variant="body1" sx={{ color: 'red', mt: 2 }}>
                    {serverError}
                </Typography>
            )}
            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="inherit"
                onClick={handleSubmit}
                disabled={isDDS}
                sx={{ mt: 3 }}
            >
                Добави фирма
            </LoadingButton>
        </>
    );


    return (
        <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
            <Card
                sx={{
                    p: 5,
                    width: 1,
                }}
            >
                <Typography variant="h4">За да продължите, моля добавете вашата фирма</Typography>
                <Box sx={{ mt: 3 }}>{renderForm}</Box>
            </Card>
        </Stack>
    );
};

export default AddCompanyForm;
